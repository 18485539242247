@font-face {
	font-family: Inter;
    font-style: normal;
    font-weight: normal;
    src: url("./styles/Inter/static/Inter-Regular.ttf");
}

@font-face {
	font-family: Inter;
    font-style: normal;
    font-weight: bold;
    src: url("./styles/Inter/static/Inter-Bold.ttf");
}

@font-face {
	font-family: Inter;
    font-style: normal;
    font-weight: 800;
    src: url("./styles/Inter/static/Inter-ExtraBold.ttf");
}

html, body {
    background-color: white;
    font-family: Inter;
    height: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .5s ease;
    opacity: 0.5;
}

.overlay:hover {
    opacity: 0.8;
}

.tab-content>.active {
    display: flex;
}

.risk-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    /* transition: .5s ease; */
}

.risk-text {
    text-align: center;
}


.risk-card {
    border: 0;
    background-color: transparent;
}

.file-input {
    opacity: 0;
    -moz-opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
    width: 200px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    height: 50px;
    display: block;
}

.card-group {
    display: flex;
    flex-flow: row wrap;
}

.card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
}

@media (max-width: 992px) {
    _::-webkit-full-page-media, _:future, :root .card-group > .card {
        height: 46.6px;
    }
}

@media (min-width: 992px) {
    _::-webkit-full-page-media, _:future, :root .card-group > .card {
        height: 76.6px;
    }
}

@media (min-width: 992px) {
    _::-webkit-full-page-media, _:future, :root .card-group > .card {
        height: 76.6px;
    }
}

.archive-entry {
    margin: 1%;
    cursor: pointer;
}

@media (min-width: 576px) {

    .archive-gallery .transform-on-hover:hover {
        transform: translateY(-10px) scale(1.02);
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15) !important;
    }
}

.search-box {
    width: 120px;
}

.archive-card-body {
    padding-top: 1.25em;
    padding-bottom: 1.25em;
    flex: 1 1 auto;
    min-height: 1px;
}

.search-row {
    justify-content: center;
    flex-wrap: nowrap;
    padding-bottom: 24px;
}

.error-modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
}

.error-modal-content .modal-header {
    border-bottom: none;
    position: relative;
}

.error-modal-content .icon-box {
    color: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -70px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    background: #ef513a;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0 0 0 25);
}

.error-modal-content .icon-box svg {
    font-size: 56px;
    position: relative;
    top: 4px;
}

.error-modal-content h4 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -15px;
}

.error-modal-content .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
}

.error-modal-content .btn {
    color: #fff;
    border-radius: 4px;
    background: #ef513a;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;
}

.error-modal-container {
    margin-top: 80px;
}